import tw from 'twin.macro';

export const PressReleasesWrapper = tw.div`flex flex-wrap w-full`;

export const PressReleaseContainer = tw.div`lg:grid-cols-8 sm:grid-cols-2 mx-auto w-full px-5 mb-5`;

export const PressReleaseDateWrapper = tw.div`flex flex-col md:grid md:grid-cols-[160px 1fr] mb-4 w-full md:items-center`;

export const PressReleaseDate = tw.div`text-sm`;

export const PressReleaseTitle = tw.div`flex-1 text-base lg:text-lg text-left`;

export const Link = tw.a`text-lg text-blue-700 tracking-normal`;

export const ExpandButton = tw.button`bg-white hover:bg-gray-300 text-gray-500 font-bold py-2 px-4 rounded-lg  border border-gray-300 hover:border-transparent`;
