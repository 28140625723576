import tw from 'twin.macro';

export const Section = tw.section`w-full bg-white py-8 md:py-16 px-8`;

export const Container = tw.div`container mx-auto grid gap-4 md:grid-cols-2 xl:grid-cols-4`;

export const CardContainer = tw.div`w-full h-96 lg:w-11/12 pb-4 flex rounded-lg shadow-lg cursor-pointer items-stretch pb-2 md:h-96 sm:h-96 sm:mb-5`;

export const Image = tw.img`h-48 w-full object-cover m-0 rounded-t-lg`;

export const TextContainer = tw.div`flex-1 bg-white py-4 px-6 flex flex-col justify-between text-left`;

export const ArticleTitle = tw.p`text-base font-semibold text-gray-900 text-left`;

export const Description = tw.p`mt-2 text-sm text-gray-600 text-left`;
