import tw from 'twin.macro';

export const Header = tw.h3`px-8 text-2xl tracking-wide font-semibold underline`;

export const Wrapper = tw.div`container w-full flex flex-wrap items-start text-center`;

export const Container = tw.div`w-full md:w-1/2 p-8`;

export const Link = tw.a`text-xl text-blue-700 tracking-normal`;

export const ImageTextRowHeader = tw.h3`font-display text-2xl md:text-3xl text-gray-800 font-bold leading-none mb-4 `;

export const Paragraph = tw.div`text-gray-700 text-lg`;

export const Socials = tw.div`flex flex-wrap items-center w-full space-x-4 justify-center`;

export const SocialWrapper = tw.div`inline-flex mb-0`;

export const SocialLink = tw.a`no-underline hover:underline text-gray-800 cursor-pointer`;
