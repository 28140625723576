import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io';
import { BsYoutube } from 'react-icons/bs';
import { SiGiphy } from 'react-icons/si';

import { Hero } from '../components/Hero/';
import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import Section from '../components/Section/';
import { Badges } from '../components/Badges/';
import CompanyTestimonials from '../components/CompanyTestimonials/';
import {
  Header,
  Wrapper,
  Container,
  Link,
  ImageTextRowHeader,
  Socials,
  SocialWrapper,
  SocialLink,
} from '../styles/presse';
import { AnchorLinks } from '../components/AnchorLinks';
import SuccessCases from '../components/SuccessCases';
import { ImageTextRow } from '../components/ImageTextRow';
import NewsletterForm from '../components/NewsletterForm';
import { PressReleases } from '../components/PressReleases';
import { Press } from '../components/Press';
import { CarouselBlog } from '../components/CarouselBlog';

const Security = ({
  data: {
    Hero: HeroImage,
    MartinFront,
    OsamahFront,
    YasminFront,
    Martin,
    Osamah,
    Yasmin,
    Lady: LadyImage,
    Lady2: LadyImage2,
    Man2: ManImage2,
  },
}) => (
  <PageLayout css={{ background: 'white !important', overflowX: 'hidden' }}>
    <SEO title="Pressebereich" />
    <Hero image={HeroImage} title="Presse">
      <div className="font-display font-xl tracking-wide">
        Jetzt die DropFriends-App downloaden.
      </div>
      <Badges />
    </Hero>
    <CompanyTestimonials />
    <AnchorLinks
      links={[
        { title: 'Allgemein', anchor: '/presse#allgemein' },
        { title: 'Blog', anchor: '/presse#blog' },
        { title: 'Presseberichte', anchor: '/presse#pressemappe' },
        { title: 'Bilder', anchor: '/presse#downloads' },
      ]}
    />

    <SuccessCases titleId="allgemein" />

    <ImageTextRow leftImageFluid={LadyImage} fullWidth>
      <div className="flex justify-center">
        <ImageTextRowHeader>Unsere Netzwerke</ImageTextRowHeader>
      </div>

      <Socials>
        <SocialWrapper>
          <SocialLink
            href="https://www.youtube.com/channel/UC4VI1TxC9ntkNTeWaufWQUQ"
            target="_blank"
            rel="noopener noreferrer"
            className={`hover:text-red-700 `}>
            <BsYoutube className="w-10 h-10 md:h-12 md:w-12" />
          </SocialLink>
        </SocialWrapper>
        <SocialWrapper>
          <SocialLink
            href="https://instagram.com/dropfriends.app"
            target="_blank"
            rel="noopener noreferrer"
            className={`hover:text-pink-500`}>
            <IoLogoInstagram className="w-10 h-10 md:h-12 md:w-12" />
          </SocialLink>
        </SocialWrapper>
        <SocialWrapper>
          <SocialLink
            href="https://www.linkedin.com/company/dropfriends/?originalSubdomain=de"
            target="_blank"
            rel="noopener noreferrer"
            className={`hover:text-blue-700`}>
            <IoLogoLinkedin className="w-10 h-10 md:h-12 md:w-12" />
          </SocialLink>
        </SocialWrapper>
        <SocialWrapper>
          <SocialLink
            href="https://www.facebook.com/DropFriendsApp/"
            target="_blank"
            rel="noopener noreferrer"
            className={`hover:text-blue-400`}>
            <IoLogoFacebook className="w-10 h-10 md:h-12 md:w-12" />
          </SocialLink>
        </SocialWrapper>
        <SocialWrapper>
          <SocialLink
            href="https://giphy.com/dropfriends"
            target="_blank"
            rel="noopener noreferrer"
            className={`hover:text-blue-400`}>
            <SiGiphy className="w-8 h-8 md:w-10 md:h-10" />
          </SocialLink>
        </SocialWrapper>
      </Socials>
    </ImageTextRow>

    <ImageTextRow
      leftImageFluid={ManImage2}
      rightImageMobileLast
      rightImageFluid={LadyImage2}
      className="justify-center"
      fullWidth
    />

    <Press />

    <CarouselBlog />

    <section className="w-full mx-auto text-center py-16 md:py-24 px-8 md:px-16 bg-primaryLight mt-10">
      <h2 className="font-display w-full my-2 text-2xl md:text-3xl lg:text-5xl font-bold leading-tight text-center">
        Jetzt die DropFriends-App downloaden.
      </h2>
      <h3 className="font-display mb-4 text-xl lg:text-3xl leading-tight">
        Erhältlich im App Store und im Google Play Store.
      </h3>
      <Badges className="my-4" />
      <NewsletterForm />
    </section>

    <PressReleases
      pressReleases={[
        {
          title: 'Wie der Nachbarn zum Paketshop werden soll',
          href: 'https://rp-online.de/wirtschaft/start-up-dropfriends-mehr-paketshops-braucht-das-land_aid-72871489',
          date: '13. Juli 2022',
        },
        {
          title: 'DropFriends erhält sechsstellige Summe',
          href: 'https://www.startbase.de/news/dropfriends-erhaelt-sechsstellige-summe/',
          date: '07. Juli 2022',
        },
        {
          title: 'Pre-Seed Investment für Paket-Community Startup DropFriends',
          href: 'https://www.unitednetworker.com/dropfriends-pre-seed-finanzierung/',
          date: '07. Juli 2022',
        },
        {
          title:
            'b-rayZ bekommt 4 Millionen - HTGF investiert in DeepSkill - DropFriends sammelt Geld ein',
          href: 'https://www.deutsche-startups.de/2022/07/07/dealmonitor-b-rayz-deepskill/',
          date: '07. Juli 2022',
        },
        {
          title: 'In die Box statt an die Tür',
          href: 'https://magazin.ihk-muenchen.de/artikel/ecommerce-klimafreundliche-zustellung-in-die-box-statt-an-die-tuer',
          date: '01. Juni 2022',
        },
        {
          title: 'Von der Idee im Dönerladen bis zum Last-Mile-Business? - mit DropFriends',
          href: 'https://a2x.io/podcast/von-der-idee-im-donerladen-bis-zum-last-mile-business-mit-dropfriends/',
          date: '02. Dezember 2021',
        },
        {
          title: 'Hermes stellt Dienst ein: Tausende Annahmestellen geschlossen',
          href: 'https://www.inside-digital.de/news/hermes-stellt-dienst-ein-tausende-annahmestellen-geschlossen',
          date: '04. März 2021',
        },
        {
          title: 'Startport holt 30 Logistik-Start-ups an Bord',
          href: 'https://www.dvz.de/digitalisierung/startups/detail/news/startport-holt-30-logistik-start-ups-an-bord.html',
          date: '09. Februar 2021',
        },
        {
          title: 'Mit erfolgreicher Erstzustellung die Umwelt schonen',
          href: 'https://www.keepist.de/blog/erfolgreiche-erstzustellung-umwelt-entlasten/',
          date: '10. November 2020',
        },
        {
          title: 'Gründer trotzen Covid-19: Wie drei Startups mit ihren Angeboten helfen - Teil 3',
          href: 'https://www.startplatz.de/gruender-trotzen-covid-19-wie-drei-startups-mit-ihren-angeboten-helfen-teil-3/',
          date: '15. April 2020',
        },
        {
          title: 'DropFriends zahlt Nachbarn 50 Cents bis 1 Euro fürs Annehmen von Paketen',
          href: 'https://www.paketda.de/news-dropfriends-geld-verdienen-paketannahme.html',
          date: '25. März 2020',
        },
        {
          title: 'Paketfuxx & DropFriends: Pakete annehmen und Geld verdienen',
          href: 'https://www.pcwelt.de/news/Paketfuxx-DropFriends-Pakete-annehmen-und-Geld-verdienen-10770871.html',
          date: '12. März 2020',
        },
        {
          title: 'So verdienst du Geld mit den Paketen deiner Nachbarn',
          href: 'https://www.inside-digital.de/news/paket-fuer-den-nachbarn-angenommen-jetzt-gibts-geld-dafuer',
          date: '11. März 2020',
        },
        {
          title: '11 ganz neue und extrem frische Gründerideen aus Köln',
          href: 'https://www.deutsche-startups.de/2020/02/11/11-frische-startups-koeln/',
          date: '11. Februar 2020',
        },
        {
          title: 'Drop it like it`s hot - Alles für die Community mit DropFriends',
          href: 'https://www.handelskraft.de/drop-it-like-its-hot-alles-fuer-die-community-mit-dropfriends-netzfund/',
          date: '20. Dezember 2019',
        },
        {
          title: 'DropFriends macht den Nachbarn zum offiziellen Paketshop',
          href: 'https://t3n.de/news/paketshops-crowdsourcing-dropfriends-drop-friends-1231261/',
          date: '07. Dezember 2019',
        },
      ]}
    />

    <Section className="py-8 font-display" title="Downloads" titleId="downloads">
      <Header className="mt-8">Logos</Header>
      <Wrapper className="mb-16">
        <Container>
          <img src="/logo1.png" alt="Standard Logo" />
          <div className="text-sm">Standard Logo</div>
          <Link href="/logo1.png" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <img src="/logo2.png" alt="Weißes Logo auf orangenem Hintergrund" />
          <div className="text-sm">Weißes Logo auf orangenem Hintergrund</div>
          <Link href="/logo2.png" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <img src="/logo3.png" alt="Schwarzes Logo auf weißem Hintergrund" />
          <div className="text-sm">Schwarzes Logo auf weißem Hintergrund</div>
          <Link href="/logo3.png" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <img src="/logo4.png" alt="Weißes Logo auf blauem Hintergrund" />
          <div className="text-sm">Weißes Logo auf blauem Hintergrund</div>
          <Link href="/logo4.png" target="_blank">
            Download
          </Link>
        </Container>
      </Wrapper>
      <Header>Gründer</Header>
      <Wrapper>
        <Container>
          <GatsbyImage
            image={MartinFront.childImageSharp.gatsbyImageData}
            className="w-full mb-4"
            alt=""
          />
          <div className="text-sm">
            Die DropFriends-Gründer (von links nach rechts):
            <br />
            Yasmin Werner, Martin Peters, Osamah Aldoaiss
          </div>
          <Link href="/gruender-martin-front.jpg" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <GatsbyImage
            image={OsamahFront.childImageSharp.gatsbyImageData}
            className="w-full mb-4"
            alt=""
          />
          <div className="text-sm">
            Die DropFriends-Gründer (von links nach rechts):
            <br />
            Yasmin Werner, Osamah Aldoaiss, Martin Peters
          </div>
          <Link href="/gruender-osamah-front.jpg" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <GatsbyImage
            image={YasminFront.childImageSharp.gatsbyImageData}
            className="w-full mb-4"
            alt=""
          />
          <div className="text-sm">
            Die DropFriends-Gründer (von links nach rechts):
            <br />
            Osamah Aldoaiss, Yasmin Werner, Martin Peters
          </div>
          <Link href="/gruender-yasmin-front.jpg" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <GatsbyImage
            image={Martin.childImageSharp.gatsbyImageData}
            className="w-full mb-4"
            alt=""
          />
          <div className="text-sm">Gründer und Geschäftsführer: Martin Peters</div>
          <Link href="/martin-peters.jpg" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <GatsbyImage
            image={Osamah.childImageSharp.gatsbyImageData}
            className="w-full mb-4"
            alt=""
          />
          <div className="text-sm">Gründer: Osamah Aldoaiss</div>
          <Link href="/osamah-aldoaiss.jpg" target="_blank">
            Download
          </Link>
        </Container>
        <Container>
          <GatsbyImage
            image={Yasmin.childImageSharp.gatsbyImageData}
            className="w-full mb-4"
            alt=""
          />
          <div className="text-sm">Gründerin: Yasmin Werner</div>
          <Link href="/yasmin-werner.jpg" target="_blank">
            Download
          </Link>
        </Container>
      </Wrapper>
    </Section>
  </PageLayout>
);

export const query = graphql`
  {
    Hero: file(relativePath: { eq: "presse/hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Lady: file(relativePath: { eq: "presse/lady-blue-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, layout: FULL_WIDTH)
      }
    }
    Lady2: file(relativePath: { eq: "presse/lady-yellow-bg.png" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, layout: FULL_WIDTH)
      }
    }
    Man2: file(relativePath: { eq: "presse/man-pink-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, layout: FULL_WIDTH)
      }
    }
    MartinFront: file(relativePath: { eq: "gruender-martin-front.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "800px", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    OsamahFront: file(relativePath: { eq: "gruender-osamah-front.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "800px", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    YasminFront: file(relativePath: { eq: "gruender-yasmin-front.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "800px", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Martin: file(relativePath: { eq: "martin-peters.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "800px", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Osamah: file(relativePath: { eq: "osamah-aldoaiss.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "800px", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Yasmin: file(relativePath: { eq: "yasmin-werner.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "800px", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default Security;
