import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div(({ fullWidth }) => [
  tw`flex flex-wrap items-center`,
  fullWidth ? tw`w-screen` : 'container',
]);

export const ImageContainer = styled.div(({ leftImageMobileLast, rightImageMobileLast }) => [
  tw`w-full md:w-1/2 h-64 md:h-full bg-center bg-cover bg-no-repeat`,
  leftImageMobileLast ? tw`order-last md:order-first` : '',
  rightImageMobileLast ? tw`order-last` : tw`order-first`,
]);
export const RightImageContainer = styled.div(({ leftImageMobileLast, rightImageMobileLast }) => [
  tw`md:order-last w-full md:w-1/2 h-64 md:h-full bg-center bg-cover bg-no-repeat`,
  rightImageMobileLast ? tw`order-last` : tw`order-first`,
]);

export const SmallImageContainer = styled.div(({ position, lessYPadding }) => [
  tw`w-full md:w-1/2 px-6 md:px-8 lg:px-16 flex`,
  position === 'right' ? tw`md:justify-end` : tw`justify-start`,
  lessYPadding ? tw`py-4 md:py-12` : tw`py-16 md:py-32 `,
]);
