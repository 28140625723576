import React from 'react';
import { IoMdDownload } from 'react-icons/io';

import { CompanyDescription, Grid, Image, Section } from './styles';
import inVest from '../../images/presse/InVest-Logo.png';
import NRWBank from '../../images/presse/NRW-Bank-Logo.jpg';
import RheinlandPitch from '../../images/presse/RheinlandPitch-Logo2.png';
import Startport from '../../images/presse/Startport-Logo.png';
import { Link } from '../../styles/presse';

const SuccessCases = ({ titleId = '' }) => {
  return (
    <Section id={titleId}>
      <Grid>
        <div className="h-full grid" style={{ gridTemplateRows: '120px 1fr' }}>
          <div className="h-24 w-full flex items-center justify-center">
            <Image src={NRWBank} alt="NRWBank" />
          </div>
          <CompanyDescription>
            Mittlere 6-stellige Pre-Seed Finanzierungsrunde (Business Angels + NRW Bank)
          </CompanyDescription>
        </div>
        <div className="h-full grid" style={{ gridTemplateRows: '120px 1fr' }}>
          <div className="h-24 w-full flex items-center justify-center">
            <Image src={inVest} alt="inVest" />
          </div>
          <CompanyDescription>
            Als innovatives TechStartup durch das Forschungzentrum Jülich vom BMWi und Bafa
            bestätigt und somit „INVEST“ gefördert
          </CompanyDescription>
        </div>
        <div className="h-full grid" style={{ gridTemplateRows: '120px 1fr' }}>
          <div className="h-24 w-full flex items-center justify-center">
            <Image src={RheinlandPitch} alt="RheinlandPitch" />
          </div>
          <CompanyDescription>Gewinner des Rheinland Pitches #98</CompanyDescription>
        </div>
        <div className="h-full grid" style={{ gridTemplateRows: '120px 1fr' }}>
          <div className="h-24 w-full flex items-center justify-center">
            <Image src={Startport} alt="Startport" />
          </div>
          <CompanyDescription>
            Im Batch #5 des startport Logistik Innovationshubs des Duisburger Hafens
          </CompanyDescription>
        </div>
      </Grid>
      <div className="pt-24 w-full flex items-center justify-center">
        <Link href="/071422_DF_Pressemappe_V2.pdf" download>
          <div className="inline-flex">
            Pressemappe herunterladen
            <span>
              <IoMdDownload className="w-5 h-5 md:h-6 md:w-6 ml-2" />
            </span>
          </div>
        </Link>
      </div>
    </Section>
  );
};

export default SuccessCases;
