import React, { useState } from 'react';
import Section from '../Section';
import {
  ExpandButton,
  Link,
  PressReleaseContainer,
  PressReleaseDate,
  PressReleaseDateWrapper,
  PressReleasesWrapper,
  PressReleaseTitle,
} from './styles';

export const PressReleases = ({ pressReleases = [], className = '' }) => {
  const [expanded, setExpanded] = useState(false);
  const dataForDisplay = expanded ? pressReleases : pressReleases.slice(0, 5);

  return (
    <Section title="Presseberichte" titleId="pressemappe" className="md:py-16">
      <PressReleaseContainer>
        <PressReleasesWrapper>
          {dataForDisplay.map((press, index) => {
            return (
              <PressReleaseDateWrapper key={`pressebericht-${index}`}>
                <PressReleaseDate className="tabular-numbers">{press.date}</PressReleaseDate>
                <Link href={press.href} target="_blank" rel="noopener noreferrer">
                  <PressReleaseTitle>{press.title}</PressReleaseTitle>
                </Link>
              </PressReleaseDateWrapper>
            );
          })}
        </PressReleasesWrapper>
        <div className="flex justify-center ">
          <ExpandButton type="button" onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Weniger anzeigen' : 'Mehr anzeigen'}
          </ExpandButton>
        </div>
      </PressReleaseContainer>
    </Section>
  );
};
