import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

import { ArticleTitle, CardContainer, Description, Image, TextContainer } from './styles';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Section from '../Section';

export const CarouselBlog = () => {
  const [swiper, setSwiper] = React.useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();

  React.useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <Section titleId="blog" title="Aus unserem Blog">
      <div className="carousel-container">
        <div className="swiper-button" ref={prevRef}>
          <GrFormPrevious />
        </div>

        <Swiper
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          loop={true}
          modules={[Navigation]}
          slidesPerView={4}
          className="mySwiper"
          onSwiper={setSwiper}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}>
          {articles.map(({ title, link, image, description }, index) => (
            <SwiperSlide className="lg:mb-12" key={`BlogArtikel${index}+${title}`}>
              <CardContainer>
                <a href={link} target="_blank">
                  <div className="flex-shrink-0">
                    <Image src={image} alt="" />
                  </div>
                  <TextContainer>
                    <div className="flex-1">
                      <div href={link}>
                        <ArticleTitle>{title}</ArticleTitle>
                        <Description>{description}</Description>
                      </div>
                    </div>
                  </TextContainer>
                </a>
              </CardContainer>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button" ref={nextRef}>
          <GrFormNext />
        </div>
      </div>
    </Section>
  );
};

export const articles = [
  {
    title: 'Warum Packstationen keine 100%ige Lösung sind',
    link: 'https://blog.dropfriends.com/2020/10/12/warum-packstationen-keine-losung-sind/',
    image:
      'https://blog.dropfriends.com/wp-content/uploads/2020/06/twitter-jankolario-Q-fFZtc1hS4-unsplash-copy-2048x1526.jpg',
    description:
      'DHL Packstationen, Amazon Lockers und Paketstationen der Deutschen Bahn möchten die Erstzustellung für Sendungen erleichtern. Doch ein Blick in die…',
  },
  {
    title: 'Warum DropPoints so sicher sind',
    link: 'https://blog.dropfriends.com/2020/06/09/warum-droppoints-so-sicher-sind/',
    image: 'https://blog.dropfriends.com/wp-content/uploads/2020/08/48308_small.jpg',
    description:
      'In diesem Blog-Posting möchte ich erklären, warum die Ablage von Deinen Paketen bei einem DropPoint so sicher ist und warum es Dir nicht egal sein...',
  },
  {
    title: 'Pakete annehmen, hilft der Umwelt',
    link: 'https://blog.dropfriends.com/2020/06/18/pakete-annehmen-hilft-der-umwelt/',
    image: 'https://blog.dropfriends.com/wp-content/uploads/2020/10/Stadt_02_small.jpg',
    description:
      'Seit Fridays for Future ist das Thema „Nachhaltigkeit“ und „das Reduzieren von CO2 Ausstoß“ in der Mitte der Bevölkerung, bei Konzernen und Kleinstunternehmen...',
  },
  {
    title: `Selfies für Pakete`,
    link: 'https://blog.dropfriends.com/2020/06/25/selfies-fuer-pakete/',
    image:
      'https://blog.dropfriends.com/wp-content/uploads/2020/06/portrait-of-a-cheerful-young-man-taking-a-selfie_small.jpg',
    description:
      'Dokumentationen zur Zustellung gehören dazu. Was über Jahrzehnte mit einer Unterschrift vom Boten durchgeführt wurde, reicht in der heutigen Zeit aber...',
  },
  {
    title: `Last Mile City Logistics | DropFriends on Tour
      `,
    link: 'https://blog.dropfriends.com/2022/07/12/dropfriends-on-tour-lmcl-recap/',
    image:
      'https://blog.dropfriends.com/wp-content/uploads/2022/07/DE9F9915-295A-485B-A455-37ABA192F26D.jpg',
    description:
      'Die Last Mile City Logistics ist eine Messe, die sich speziell mit Themen rund um die „letzte logistische Meile“ der Supply Chain beschäftigt. Das ist der Teil...',
  },
  {
    title: `Wir sind Giphy Millionäre`,
    link: 'https://blog.dropfriends.com/2022/01/24/wir-sind-giphy-millionaere/',
    image: 'https://blog.dropfriends.com/wp-content/uploads/2022/01/Bild-von-iOS-37-768x1024.jpg',
    description:
      'Wir bei DropFriends haben ein erfolgreiches 2021 hinter uns und können mit stolzer Brust behaupten: Wir sind Millionäre! Giphy Millionäre.',
  },
];
