import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import tw from 'twin.macro';

import { ImageContainer, RightImageContainer, SmallImageContainer, Wrapper } from './styles';

export const ImageTextRow = ({
  children,
  className = '',
  containerLessYPadding = false,
  image,
  rightImageMobileLast,
  rightImageFluid,
  leftImageMobileLast,
  leftImageFluid,
  position,
  fullWidth,
  id = '',
}) => {
  return (
    <Wrapper id={id} fullWidth={fullWidth} className={className}>
      {leftImageFluid ? (
        <ImageContainer leftImageMobileLast={leftImageMobileLast}>
          <GatsbyImage
            className="w-full h-full"
            objectFit="cover"
            objectPosition="center center"
            alt=""
            image={leftImageFluid.childImageSharp.gatsbyImageData}
          />
        </ImageContainer>
      ) : null}
      {image && position === 'left' ? (
        <ImageContainer
          tw="justify-center"
          style={{ backgroundImage: `url(${image})` }}></ImageContainer>
      ) : null}
      {children ? (
        <SmallImageContainer
          tw="justify-center"
          position={position}
          lessYPadding={containerLessYPadding}>
          <div className="w-full align-middle" style={{ maxWidth: 576 }}>
            {children}
          </div>
        </SmallImageContainer>
      ) : null}
      {image && position === 'right' ? (
        <ImageContainer
          className="order-first md:order-last"
          style={{ backgroundImage: `url(${image})` }}></ImageContainer>
      ) : null}
      {rightImageFluid ? (
        <RightImageContainer rightImageMobileLast={rightImageMobileLast} className="md:order-last">
          <GatsbyImage
            className="w-full h-full"
            objectFit="cover"
            objectPosition="center center"
            alt=""
            image={rightImageFluid.childImageSharp.gatsbyImageData}
          />
        </RightImageContainer>
      ) : null}
    </Wrapper>
  );
};
